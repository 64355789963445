import * as React from "react"
import styled from "styled-components"

const Container = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>
}

export default Container

const StyledContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: initial 1.5rem;
  text-align: initial;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    max-width: 960px;
  }
`
