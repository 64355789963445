import * as React from "react"
import styled from "styled-components"

const PageContent = props => {
  return <MainContent dark={props.dark}>{props.children}</MainContent>
}

export default PageContent

const MainContent = styled.article`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  background-color: ${props =>
    props.dark
      ? ({ theme }) => theme.primaryDark
      : ({ theme }) => theme.bgLight};
  color: ${props =>
    props.dark
      ? ({ theme }) => theme.primaryLight
      : ({ theme }) => theme.primaryDark};

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    min-height: 60vh;
  }
`
