import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import Container from "../components/Container"
import PageContent from "../components/PageContent"

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const About = ({ data }) => {
  const page = data.pageContent

  return (
    <Layout>
      <Seo title="About Joss Cannon" />
      <PageContent>
        <Container>
          <PageHeader>
            <PageTitle>About</PageTitle>
          </PageHeader>
          <FlexContainer>
            {page.mainImage != null && (
              <MainImage
                image={page.mainImage.asset.gatsbyImageData}
                alt={page.altText}
              />
            )}
            <ContentContainer>
              <Title>Joss Cannon</Title>
              <BlockContent blocks={page._rawBody} serializers={serializers} />
            </ContentContainer>
          </FlexContainer>
        </Container>
      </PageContent>
    </Layout>
  )
}

export default About

const PageHeader = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 6rem;
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    margin-top: 7rem;
  }
`

const PageTitle = styled.h1`
  color: ${({ theme }) => theme.primaryDark};

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    display: none;
  }
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
`

const MainImage = styled(GatsbyImage)`
  width: 100%;
  max-height: 60vh;
  border-radius: 4px;
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    width: 60%;
    max-height: 50vmax;
  }

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    width: 40%;
    flex-shrink: 0;
    flex-grow: 0;
  }
`

const ContentContainer = styled.div`
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    width: 55%;
    margin-left: 2rem;
    flex-shrink: 1;
    flex-grow: 1;
  }
`

const Title = styled.h3`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.primaryDark};
  margin-bottom: 0.75rem;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    font-size: 1.75rem;
    margin-bottom: 2rem;
  }
`

export const query = graphql`
  query AboutPage {
    pageContent: sanityPage(slug: { current: { eq: "about" } }) {
      mainImage {
        asset {
          gatsbyImageData
        }
      }
      slug {
        current
      }
      title
      _rawBody
      altText
    }
  }
`
